/* eslint-disable no-console */
import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";
import { IconControlsMicrophone, TypeIcon } from "../IconsControls";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import ZoomClientContext from "@/components/LiveSessionsZoom/context/zoom-context";
import { Trans } from "react-i18next";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";

export const Microphone = () => {
  const { hasRoles } = useUser();
  const isMentorOrAdmin = hasRoles([UserRole.Mentor, UserRole.Admin]);
  const [isStartedAudio, setIsStartedAudio] = useState(false);
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const { zmClient } = useContext(ZoomClientContext);
  const zoomMediaContext = useContext(ZoomMediaContext);

  const getSelectedDevices = () => {
    try {
      const selectedDevices = localStorage.getItem("selectedDevices");
      return selectedDevices ? JSON.parse(selectedDevices) : null;
    } catch {
      return null;
    }
  };

  const initializeAudio = useCallback(async () => {
    // Verificar que estamos realmente conectados antes de inicializar
    if (!zmClient?.getSessionInfo()?.isInMeeting) {
      console.log("Session not ready for audio initialization");
      return;
    }

    try {
      const mediaStream = zoomMediaContext?.mediaContext?.mediaStream;
      if (!mediaStream) {
        console.log("Media stream not available");
        return;
      }

      if (!isAudioInitialized) {
        const selectedDevices = getSelectedDevices();

        // Intentar inicializar el audio
        await mediaStream.startAudio({
          speakerId: selectedDevices?.speaker,
          mute: true, // Empezar siempre muteado
        });

        await mediaStream.muteAudio();
        setIsAudioInitialized(true);
        setIsStartedAudio(false);
        console.log("Audio initialized successfully");
      }
    } catch (error) {
      console.error("Error initializing audio:", error);
      setIsAudioInitialized(false);
      setIsStartedAudio(false);
    }
  }, [
    zmClient,
    zoomMediaContext?.mediaContext?.mediaStream,
    isAudioInitialized,
  ]);

  const onMicrophoneHandle = useCallback(async () => {
    if (!isMentorOrAdmin || !zoomMediaContext?.mediaContext?.mediaStream) {
      return;
    }

    try {
      const mediaStream = zoomMediaContext.mediaContext.mediaStream;

      // Verificar que la sesión está activa
      if (!zmClient?.getSessionInfo()?.isInMeeting) {
        console.log("Session not active");
        return;
      }

      if (!isAudioInitialized) {
        await initializeAudio();
      }

      if (!isStartedAudio) {
        await mediaStream.unmuteAudio();
        setIsStartedAudio(true);
      } else {
        await mediaStream.muteAudio();
        setIsStartedAudio(false);
      }
    } catch (error) {
      console.error("Error toggling microphone:", error);
      // Reinicializar en caso de error
      setIsAudioInitialized(false);
      setIsStartedAudio(false);
    }
  }, [
    isStartedAudio,
    isAudioInitialized,
    zmClient,
    zoomMediaContext?.mediaContext?.mediaStream,
    isMentorOrAdmin,
    initializeAudio,
  ]);

  // Solo inicializar cuando la sesión esté realmente conectada
  useEffect(() => {
    const isSessionReady = zmClient?.getSessionInfo()?.isInMeeting;
    const mediaStream = zoomMediaContext?.mediaContext?.mediaStream;

    if (isSessionReady && mediaStream && !isAudioInitialized) {
      initializeAudio();
    }

    return () => {
      // Cleanup solo si hay una sesión activa
      if (mediaStream && zmClient?.getSessionInfo()?.isInMeeting) {
        try {
          mediaStream.muteAudio();
          setIsStartedAudio(false);
        } catch (error) {
          console.warn("Error in cleanup:", error);
        }
      }
    };
  }, [
    zmClient,
    zoomMediaContext?.mediaContext?.mediaStream,
    isAudioInitialized,
    initializeAudio,
  ]);

  // Manejar actualizaciones de usuario
  useEffect(() => {
    const handleUserUpdate = () => {
      try {
        if (!zmClient?.getSessionInfo()?.isInMeeting) return;

        const currentUser = zmClient?.getCurrentUserInfo();
        if (!currentUser) return;

        const users = zmClient?.getAllUser();
        if (!users) return;

        const user = users.find((u) => u.userId === currentUser.userId);
        if (user) {
          setIsStartedAudio(!user.muted);
        }
      } catch (error) {
        console.warn("Error updating user state:", error);
      }
    };

    if (zmClient) {
      zmClient.on("user-updated", handleUserUpdate);
      return () => {
        zmClient.off("user-updated", handleUserUpdate);
      };
    }
  }, [zmClient]);

  return (
    <Box>
      <Flex
        onClick={onMicrophoneHandle}
        cursor={isMentorOrAdmin ? "pointer" : "not-allowed"}
        w={"115px"}
        maxW={"115px"}
        userSelect={"none"}
        justify={"center"}
        align={"center"}
        flexDir={"column"}
      >
        <IconControlsMicrophone
          type={isStartedAudio ? TypeIcon.MICROFONE_ON : TypeIcon.MICROFONE_OFF}
        />
        <Text
          color={isStartedAudio ? "#00E324" : "#FF4848"}
          fontSize={"10px"}
          lineHeight={"19.2px"}
        >
          <Trans>{isStartedAudio ? "Active" : "Disabled"} Audio</Trans>
        </Text>
      </Flex>
    </Box>
  );
};
