/* eslint-disable no-console */
import React, { useEffect, useState, useRef } from "react";
import { Box, Text, Flex, Tooltip, SimpleGrid, Badge } from "@chakra-ui/react";
import { VideoClient } from "@zoom/videosdk";

interface NetworkStatsProps {
  zmClient?: typeof VideoClient;
}

interface SystemInfo {
  browser: {
    name: string;
    version: string;
  };
  operatingSystem: {
    name: string;
    platform: string;
  };
  isMobile: boolean;
}

interface NetworkStats {
  uploadSpeed: number;
  responseTime: number;
  stability: number;
  quality: number;
  packetLoss: number;
  hasTwoUsers: boolean;
  source: "zoom" | "browser";
}

const UPDATE_INTERVAL = 5000; // 5 seconds
const MAX_HISTORY = 10;

const NetworkStatsComponent: React.FC<NetworkStatsProps> = ({ zmClient }) => {
  const [stats, setStats] = useState<NetworkStats>({
    uploadSpeed: 0,
    responseTime: 0,
    stability: 5,
    quality: 3,
    packetLoss: 0,
    hasTwoUsers: false,
    source: "browser",
  });

  const [systemInfo, setSystemInfo] = useState<SystemInfo | null>(null);
  const latencyHistoryRef = useRef<number[]>([]);

  const getSystemInfo = async () => {
    try {
      const response = await fetch("https://httpbin.org/post", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      });

      const data = await response.json();
      const headers = data.headers;

      const browserInfo = headers["Sec-Ch-Ua"]
        ?.split(",")
        .map((item: string) => {
          const [name, version] = item.split(";v=");
          return {
            name: name.trim().replace(/"/g, ""),
            version: version?.replace(/"/g, "") || "",
          };
        })
        .filter(
          (browser: { name: string }) =>
            browser.name !== "Not?A_Brand" && browser.name !== "Not A Brand"
        )[0] || { name: "Unknown", version: "" };

      const platform =
        headers["Sec-Ch-Ua-Platform"]?.replace(/"/g, "") || "Unknown";
      const isMobile = headers["Sec-Ch-Ua-Mobile"] === "?1";

      setSystemInfo({
        browser: {
          name: browserInfo.name,
          version: browserInfo.version,
        },
        operatingSystem: {
          name: platform,
          platform: platform,
        },
        isMobile,
      });
    } catch (error) {
      console.error("Error getting system information:", error);
    }
  };

  const measureUploadSpeed = async (): Promise<number> => {
    try {
      const payloadSize = 500 * 1024;
      const payload = new Array(payloadSize).fill("X").join("");

      const startTime = performance.now();
      const response = await fetch("https://httpbin.org/post", {
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "text/plain",
        },
      });

      if (!response.ok) throw new Error("Speed test error");

      const endTime = performance.now();
      const durationSeconds = (endTime - startTime) / 1000;
      const speedMbps = ((payloadSize / 1024 / 1024) * 8) / durationSeconds;

      return Number(speedMbps.toFixed(2));
    } catch (error) {
      console.error("Error measuring upload speed:", error);
      return 0;
    }
  };

  const measureStability = (measurements: number[]): number => {
    if (measurements.length < 2) return 5;

    const variations = measurements.slice(1).map((value, index) => {
      const previous = measurements[index];
      return Math.abs((value - previous) / previous) * 100;
    });

    const averageVariation =
      variations.reduce((a, b) => a + b, 0) / variations.length;

    if (averageVariation < 5) return 5; // Excellent
    if (averageVariation < 15) return 4; // Very Good
    if (averageVariation < 30) return 3; // Good
    if (averageVariation < 50) return 2; // Fair
    return 1; // Poor
  };

  const measureLatency = async (): Promise<number> => {
    try {
      const attempts = 3;
      const results = [];

      for (let i = 0; i < attempts; i++) {
        const start = performance.now();
        await fetch("https://httpbin.org/get", {
          mode: "cors",
          cache: "no-store",
        });
        const end = performance.now();
        results.push(end - start);

        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      const sorted = results.sort((a, b) => a - b);
      const filtered = sorted.slice(1, -1);
      const average = filtered.length
        ? filtered.reduce((a, b) => a + b, 0) / filtered.length
        : sorted[1];

      return average;
    } catch {
      return 999;
    }
  };

  const updateBrowserStats = async () => {
    try {
      const latencies = await Promise.all(
        [1, 2, 3, 4, 5].map(() => measureLatency())
      );
      const averageLatency =
        latencies.reduce((a, b) => a + b, 0) / latencies.length;

      latencyHistoryRef.current.push(averageLatency);
      if (latencyHistoryRef.current.length > MAX_HISTORY) {
        latencyHistoryRef.current.shift();
      }

      const uploadSpeed = await measureUploadSpeed();
      const stability = measureStability(latencyHistoryRef.current);

      let quality = 3;

      if (stability >= 4) quality += 1;
      if (stability <= 2) quality -= 1;

      if (uploadSpeed > 8) quality += 0.5;
      if (uploadSpeed < 1) quality -= 0.5;

      if (averageLatency < 80) quality += 0.5;
      if (averageLatency > 300) quality -= 0.5;

      quality = Math.round(Math.max(1, Math.min(5, quality)));

      setStats((prev) => ({
        ...prev,
        uploadSpeed,
        responseTime: Math.round(averageLatency),
        stability,
        quality,
        source: "browser",
        hasTwoUsers: false,
      }));
    } catch (error) {
      console.error("Error updating statistics:", error);
    }
  };

  useEffect(() => {
    getSystemInfo();
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (stats.source === "browser") {
      intervalId = setInterval(() => {}, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [stats.source]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const initializeStats = () => {
      const hasTwoUsers = (zmClient?.getAllUser()?.length || 0) >= 2;

      if (!zmClient?.getSessionInfo()?.isInMeeting || !hasTwoUsers) {
        updateBrowserStats();
        intervalId = setInterval(updateBrowserStats, UPDATE_INTERVAL);
        return;
      }

      const mediaStream = zmClient.getMediaStream();
      if (!mediaStream) return;

      mediaStream.subscribeVideoStatisticData({
        encode: true,
        decode: true,
        detailed: true,
      });

      const handleVideoStats = (payload: {
        data: {
          avg_loss: number;
          jitter: number;
          rtt: number;
          bitrate: number;
        };
      }) => {
        setStats((prev) => ({
          ...prev,
          uploadSpeed: payload.data.bitrate / (1024 * 1024),
          responseTime: Math.round(payload.data.rtt),
          stability: 5 - Math.min(4, Math.floor(payload.data.jitter / 25)),
          packetLoss: payload.data.avg_loss,
          source: "zoom",
          hasTwoUsers: true,
        }));
      };

      zmClient.on("video-statistic-data-change", handleVideoStats);

      return () => {
        mediaStream.unsubscribeVideoStatisticData();
        zmClient.off("video-statistic-data-change", handleVideoStats);
      };
    };

    const cleanup = initializeStats();

    return () => {
      if (intervalId) clearInterval(intervalId);
      if (cleanup) cleanup();
    };
  }, [zmClient]);

  const getQualityText = (value: number): string => {
    if (value >= 5) return "Excelente";
    if (value >= 4) return "Muy Buena";
    if (value >= 3) return "Buena";
    if (value >= 2) return "Regular";
    return "Mala";
  };

  const getQualityColor = (value: number): string => {
    if (value >= 5) return "green.400";
    if (value >= 4) return "green.300";
    if (value >= 3) return "yellow.400";
    if (value >= 2) return "orange.400";
    return "red.400";
  };

  return (
    <Box p={4} bg="transparent" borderRadius="md" w="full">
      <Flex justify="space-between" align="center" mb={4}>
        <Text color="white" fontWeight="bold">
          Calidad de Conexión
        </Text>
        <Flex gap={2} align="center">
          <Text color="gray.300" fontSize="sm">
            {stats.source === "zoom" ? "Datos de Zoom" : ""}
          </Text>
          {systemInfo && (
            <Tooltip
              label={`${systemInfo.browser.name} ${systemInfo.browser.version}${
                systemInfo.isMobile ? " (Dispositivo Movil)" : ""
              }`}
              placement="top"
            >
              <Badge
                colorScheme={stats.source === "zoom" ? "blue" : "gray"}
                variant="subtle"
              >
                {`${systemInfo.operatingSystem.name} - ${systemInfo.browser.name}`}
              </Badge>
            </Tooltip>
          )}
        </Flex>
      </Flex>

      <SimpleGrid columns={[1, null, 2]} spacing={4}>
        <Box>
          <Tooltip label="Velocidad a la que puedes enviar datos">
            <Text color="white" mb={2}>
              Velocidad de Subida: {stats.uploadSpeed.toFixed(1)} Mbps
            </Text>
          </Tooltip>

          <Tooltip label="Tiempo que tarda tu conexion en responder">
            <Text color="white" mb={2}>
              Tiempo de Respuesta:{" "}
              {stats.responseTime < 100
                ? "Excelente"
                : stats.responseTime < 200
                ? "Bueno"
                : stats.responseTime < 300
                ? "Regular"
                : "Lento"}{" "}
              ({stats.responseTime}ms)
            </Text>
          </Tooltip>
        </Box>

        <Box>
          <Tooltip label="Qué tan estable es tu conexión">
            <Text color="white" mb={2}>
              Estabilidad: {getQualityText(stats.stability)}
            </Text>
          </Tooltip>

          <Tooltip label="Calidad general de tu conexión">
            <Text
              color={getQualityColor(stats.quality)}
              fontWeight="bold"
              fontSize="lg"
            >
              Calidad General: {getQualityText(stats.quality)}
            </Text>
          </Tooltip>

          {stats.source === "zoom" && (
            <Tooltip label="Porcentaje de informacion pérdida">
              <Text color="white" mt={2}>
                Pérdida de Datos:{" "}
                {stats.packetLoss < 1
                  ? "Mínima"
                  : stats.packetLoss < 5
                  ? "Moderada"
                  : "Alta"}
              </Text>
            </Tooltip>
          )}
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default NetworkStatsComponent;
