import { colors } from "@/components/Theme/colors";
import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const filter = definePartsStyle({
  container: defineStyle({
    px: 3,
    py: "13px",
    _hover: {
      bgColor: colors.neutral[50],
      cursor: "pointer",
      ".chakra-checkbox__control:not([data-checked])": {
        borderColor: colors.primary[200],
      },
    },
  }),
  control: defineStyle({
    rounded: "4px",
    borderColor: colors.primary[300],
    _focusVisible: {
      outline: "none",
      boxShadow: "none",
    },
    _checked: {
      borderColor: colors.primary[300],
      bgColor: colors.primary[300],
    },
  }),
  label: defineStyle({
    fontSize: "14px",
    marginLeft: "10px",
  }),
  icon: defineStyle({
    fontSize: "16px",
  }),
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: { filter },
});
